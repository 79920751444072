import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import { get as _get } from "lodash";
import storage from "redux-persist/lib/storage";

import { getDeviceMode } from "../helpers/utils";
import { DEFAULT_LANGUAGE, sidebarMenuConfig, tmpConstant } from "../helpers/constants";
import configs from "../helpers/config";

import actionTypes from "../actions";

const persistConfig = {
  key: `${configs.store_name}__language`,
  storage,
};

const loading = (state = false, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_APPLICATION_LOADING:
      return action.loading;

    default:
      return state;
  }
}

const language = (state = DEFAULT_LANGUAGE, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_LANGUAGE:
      return action.language;

    default:
      return state;
  }
}

const defaultConfig = {
  menu: sidebarMenuConfig,
}

const config = (state = defaultConfig, action) => {
  switch (action.type) {
    // case actionTypes.FETCH_APPLICATION_SUCCESS:
    //   return (action.payload.config || {});

    default:
      return state;
  }
}

const initialSize = {
  width: window.innerWidth,
  height: window.innerHeight,
  mode: getDeviceMode(window.innerWidth)
}

const viewport = (state = initialSize, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_VIEWPORT:
      return ({
        ...action.size,
        mode: getDeviceMode(action.size.width)
      });

    default:
      return state;
  }
}

const languages = combineReducers({
  defaultLang: language
});

const constants = (state = tmpConstant, action) => {
  switch (action.type) {
    case actionTypes.FETCH_APPLICATION_SUCCESS:
      // return action.payload.constants || {};
      return state;

    default:
      return state;
  }
}

const appActiveMenu = (state = "", action) => {
  switch (action.type) {
    case actionTypes.SET_ACTIVE_MENU:
      return (action.payload || "");

    default:
      return state;
  }
}

const adminMenuCollapse = (state = false, action) => {
  switch (action.type) {
    case actionTypes.SET_ADMIN_MENU_COLLAPSE:
      return (action.payload || false);

    default:
      return state;
  }
}

const initialInvoices = { status: null, data: [], message: "" };

const invoicesData = (state = initialInvoices, action) => {
  switch (action.type) {
    case actionTypes.FETCH_INVOICES_SUCCESS:
      return { status: true, data: _get(action, "data", []), message: _get(action, "message", "") };

    case actionTypes.FETCH_INVOICES_ERROR:
      return { status: false, data: [], message: _get(action, "error.message", "") };

    case actionTypes.RESET_INVOICES_ERROR:
      return initialInvoices;

    default:
      return state;
  }
}

const initialMyTeamData = { status: null, data: [], message: "" };

const myTeamData = (state = initialMyTeamData, action) => {
  switch (action.type) {
    case actionTypes.FETCH_MY_TEAM_DATA_SUCCESS:
      return { status: true, data: _get(action, "data", []), message: _get(action, "message", "") };

    case actionTypes.FETCH_MY_TEAM_DATA_ERROR:
      return { status: false, data: [], message: _get(action, "error.message", "") };

    case actionTypes.RESET_MY_TEAM_DATA_ERROR:
      return initialMyTeamData;

    default:
      return state;
  }
}

const myTeam = combineReducers({
  data: myTeamData
});

export default {
  application: combineReducers({
    loading,
    language: persistReducer(persistConfig, languages),
    config,
    viewport,
    constants,
    appActiveMenu,
    adminMenuCollapse,
    invoicesData,
    myTeam
  })
}
