import React from "react";
import { NavLink, Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import { IconLinkedin } from "../../../helpers/iconStyles";

export default React.memo(function Footer(props) {

  return (
    <footer className="footer">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-auto">
            <div className="footer-logo">
              <img src={require("../../../assets/images/svg/logo.svg")} className="img-fluid" alt="" />
            </div>
          </div>
          <div className="col-lg">
            <div className="footer-copyright text-center">
              <p>
                <FormattedMessage id="footer.copyright_text" defaultMessage="Copyright © 2023 Leads United Ltd. All rights reserved | " />
                <NavLink to="#">
                  <FormattedMessage id="footer.terms_and_conditions" defaultMessage="Terms & Conditions" />
                </NavLink>
              </p>
            </div>
          </div>
          <div className="col-lg-auto">
            <div className="footer-social">
              <Link to="#">
                <IconLinkedin width="23px" height="23px" color="#E94969" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
});