import React, { Component, lazy, Fragment, Suspense } from "react";
import { get as _get, map as _map, isEmpty as _isEmpty, isEqual as _isEqual } from "lodash";
import { matchPath } from "react-router-dom";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";

import { withRouter } from "../../components/hoc/withRouter";
import Header from "../../components/common/publicLayout/Header";
import Footer from "../../components/common/publicLayout/Footer";

import { fetchApplication } from "../../actions/application";

import config from "../../helpers/config";

const components = {
  "": lazy(() => import("./Home")),
};

class PublicPages extends Component {
  
  componentDidMount() {
    const { token, fetchApplication } = this.props;

    if (!_isEmpty(token) && (typeof fetchApplication === "function")) { fetchApplication(); }
  }

  componentDidUpdate(prevProps) {
    if (
      (!_isEqual(prevProps.token, this.props.token)) &&
      !_isEmpty(this.props.token) &&
      (typeof fetchApplication === "function")
    ) {
      fetchApplication();
    }
  }

  render() {
    const { token, location, firstName } = this.props;
    const route = _get(location, "pathname", "");

    if ((!_isEmpty(token)) && (!["/logout", "/login"].includes(route)) && ([config.getAdminURL()].includes(window.location.host))) {
      window.location = config.getFrontendURL(true, true);
      return null;
    }

    const exact = true;

    return (
      <>
        <Header isLoggedIn={!_isEmpty(token)} firstName={(firstName || "")} />
        {_map((components || {}), (TabComponent, key) => {
          const path = `/${key}`;
          const isMatched = (key) ? matchPath({ path, exact, strict: false }, route) : false;

          return (
            <Fragment key={key}>
              {(((route || "").substr(1) === key) || (isMatched)) && (
                <Suspense fallback={(<></>)}>
                  <TabComponent isLoggedIn={!_isEmpty(token)} />
                </Suspense>
              )}
            </Fragment>
          )
        })}
        <Footer />
      </>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchApplication: () => dispatch(fetchApplication())
});

const mapStateToProps = (state) => ({
  firstName: _get(state, "oauth.user.first_name", ""),
  token: _get(state, "oauth.token"),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(PublicPages)));
