import React from "react";
import { Link } from "react-router-dom";

export default React.memo(function Header(props) {

  return (
    <header className="header">
      <nav className="navbar navbar-expand-lg navbar-light bg-white" aria-label="Leads United">
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img src={require("../../../assets/images/svg/logo.svg")} className="img-fluid" alt="Leads United" />
          </Link>
        </div>
      </nav >
    </header >
  );
});