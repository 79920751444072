import { takeLatest, call, fork, select, put } from "redux-saga/effects";
import { get as _get } from "lodash";

import { getApplication, getInvoices, getMyTeamData } from "../services/application";

import {
  UPDATE_APPLICATION_LOADING, FETCH_APPLICATION_REQUEST, FETCH_APPLICATION_SUCCESS,
  FETCH_INVOICES_REQUEST, FETCH_INVOICES_SUCCESS, FETCH_INVOICES_ERROR,
  FETCH_MY_TEAM_DATA_REQUEST, FETCH_MY_TEAM_DATA_SUCCESS, FETCH_MY_TEAM_DATA_ERROR
} from "../actions/application";

function* workerFetchApplication(action) {
  try {
    yield put({ type: UPDATE_APPLICATION_LOADING, loading: true });

    const uri = yield select((state) => _get(state, "oauth.uri", ""));

    const response = yield call(getApplication, uri);

    if ((response.flag || false) === true) {
      yield put({
        type: FETCH_APPLICATION_SUCCESS,
        payload: (response.data || {})
      });
    }

  } catch (error) {

  } finally {

    yield put({ type: UPDATE_APPLICATION_LOADING, loading: false });
  }
}

function* workerFetchInvoices() {

  try {
    yield put({ type: UPDATE_APPLICATION_LOADING, loading: true });

    const response = yield call(getInvoices);

    if ((response.flag || false) === true) {
      yield put({
        type: FETCH_INVOICES_SUCCESS,
        data: _get(response, "data", {}),
      });
    } else {
      yield put({
        type: FETCH_INVOICES_ERROR,
        error: {
          title: _get(response, "title", "Alert"),
          message: _get(response, "message", "Something went wrong while fetching invoices.")
        }
      });
    }

  } catch (error) {
    yield put({
      type: FETCH_INVOICES_ERROR,
      error: {
        title: _get(error, "title", "Alert"),
        message: _get(error, "message", "Something went wrong while fetching invoices.")
      }
    });
  } finally {

    yield put({ type: UPDATE_APPLICATION_LOADING, loading: false });
  }
}

function* workerFetchMyTeamData() {

  try {
    yield put({ type: UPDATE_APPLICATION_LOADING, loading: true });

    const response = yield call(getMyTeamData);

    if ((response.flag || false) === true) {
      yield put({
        type: FETCH_MY_TEAM_DATA_SUCCESS,
        data: _get(response, "data", {}),
      });
    } else {
      yield put({
        type: FETCH_MY_TEAM_DATA_ERROR,
        error: {
          title: _get(response, "title", "Alert"),
          message: _get(response, "message", "Something went wrong while fetching my team details.")
        }
      });
    }

  } catch (error) {
    yield put({
      type: FETCH_MY_TEAM_DATA_ERROR,
      error: {
        title: _get(error, "title", "Alert"),
        message: _get(error, "message", "Something went wrong while fetching my team details.")
      }
    });
  } finally {

    yield put({ type: UPDATE_APPLICATION_LOADING, loading: false });
  }
}

function* watcherFetchApplication() {
  yield takeLatest(FETCH_APPLICATION_REQUEST, workerFetchApplication);
}

function* watcherFetchInvoices() {
  yield takeLatest(FETCH_INVOICES_REQUEST, workerFetchInvoices);
}

function* watcherFetchMyTeamData() {
  yield takeLatest(FETCH_MY_TEAM_DATA_REQUEST, workerFetchMyTeamData);
}

export default [
  fork(watcherFetchApplication),
  fork(watcherFetchInvoices),
  fork(watcherFetchMyTeamData),
];
