export default {

  // Page Titles
  "LU.home": "Leads United - A Unique B2B Lead Generation Platform",
  "LU.login": "Login - Leads United",
  "LU.sign_up": "Sign Up - Leads United",
  "LU.contact": "Contact - Leads United",
  "LU.about_us": "About Us - Leads United",
  "LU.pricing": "Pricing - Leads United",
  "LU.buy_leads": "Buy Leads - Leads United",
  "LU.sell_leads": "Sell Leads - Leads United",
  "LU.integrate": "Integrate - Leads United",
  "LU.customers": "Customers - Leads United",
  "LU.forgot_password": "Forgot Password - Leads United",
  "LU.reset_password": "Reset Password - Leads United",
  "LU.generic_page.comming_soon": "Comming soon - Leads United",
  "LU.generic_page.not_found": "Page not found - Leads United",
  "LU.api_keys": "API Keys - Leads United",
  "LU.my_team": "My Team - Leads United",
  "LU.dashboard": "Dashboard - Leads United",
  "LU.vendor_quote": "Vendor Quote - Leads United",
  "LU.my_invoice": "My Invoice - Leads United",
  "LU.vendor_reply": "Vender Reply - Leads United",
  "LU.market_place": "Marketplace - Leads United",

  // Common texts
  "email": "email",
  "username": "username",
  "password": "password",
  "first_name": "first name",
  "last_name": "last name",
  "phone_number": "phone number",
  "new_password": "new password",
  "old_password": "old password",
  "confirm_password": "confirm password",
  "privacy_policy": "privacy policy",
  "terms_and_conditions": "terms and conditions",
  "change_password": "change password",
  "logout": "Logout",
  "empty": "empty",
  "company": "company",
  "name": "name",
  "coming_soon": "coming soon",
  "under_constuction": "This page is under construction.",
  "contact": "contact",
  "sign_up": "Sign Up",
  "blog": "blog",
  "filter_by": "Filter By",
  "more": "more...",
  "address_line_1": "Address Line 1",
  "address_line_2": "Address Line 2",
  "city": "city",
  "county": "County",
  "postcode": "Postcode",
  "country": "Country",
  "api": "API",
  "dashboard": "dashboard",
  "settings": "settings",
  "message": "message",
  "select": "select",
  "sort_by": "sort by",
  "this_box": "this box",
  "register_your_interest": "let's go",

  // Common labels
  "label.select_field": "select {field}",
  "label.yes": "yes",
  "label.no": "no",

  // Modal
  "modal.confirm": "confirm",
  "modal.alert": "alert !",

  // Record count display
  "showing_records_of_total": "showing {records} out of {total}",

  //Menu
  "menu.home": "home",
  "menu.solutions": "solutions",
  "menu.solutions.buy_leads": "buy leads",
  "menu.solutions.sell_leads": "sell leads",
  "menu.solutions.integrate": "integrate",
  "menu.solutions.enrich": "enrich",
  "menu.pricing": "pricing",
  "menu.customers": "customers",
  "menu.company": "company",
  "menu.company.about_us": "about us",
  "menu.company.blog": "blog",
  "menu.company.contact": "contact",
  "menu.login": "login",
  "menu.sign_up": "sign up now",
  "menu.dashboard": "Dashboard",
  "menu.settings": "Settings",
  "menu.logout": "Logout",

  // Admin Menu
  "menu.admin.marketplace": "Marketplace",
  "menu.admin.dashboard": "Dashboard",
  "menu.admin.buy_leads": "Buy Leads",
  "menu.admin.sell_leads": "Sell Leads",
  "menu.admin.enrich": "Enrich",
  "menu.admin.api": "API",
  "menu.admin.my_team": "My Team",
  "menu.admin.my_invoice": "My Invoice",
  "menu.admin.settings": "Settings",

  //Footer
  "footer.copyright_text": "Copyright © 2023 Leads United Ltd. All rights reserved | ",
  "footer.terms_and_conditions": "Terms & Conditions",
  "footer.admin.copyright_text": "Copyright © 2023 Leads United Ltd. All rights reserved | ",
  "footer.admin.terms_and_conditions": "Terms & Conditions",

  // error validation messages
  "error.required": "{field} is required.",
  "error.are_required": "{field} are required.",
  "error.select": "Please select {field}.",
  "error.select_atleast_one": "Please select at least one {field}.",
  "error.enter": "Please enter {field}.",
  "error.valid": "Please enter valid {field}.",
  "error.invalid": "Invalid {field}.",
  "error.password_not_match": "Confirm password does not match.",
  "error.password_min_char": "Password should contains minimum 8 characters.",
  "error.password_valid_char": "Password should contain at least 3 of a-z or A-Z and a number and a special character.",
  "error.exact_number_field": "Please enter {value} number {field}.",
  "error.less_than_value": "{field} should be less than {value}.",
  "error.atleast_value": "{field} should be atleast {value}.",
  "error.something_error": "There is some error inside the fields.",
  "error.less_than_value_character": "{field} should be less than {value} characters.",
  "error.after_start_date": "End date field should be after start date",
  "error.after_start_time": "End time should be after start time",
  "error.after_today": "End date should be today or future date.",
  "error.start_after_today": "Start date should be today or future date.",
  "error.date_after_today": "Date should be today or future date.",
  "error.greatereq_than_value": "{field} should be greater than or equal to {value}.",
  "error.image_upload": "Image not uploaded successfully",
  "error.after_date": "{field} should be greater than or equal to current date",
  "error.any_one_option_required": "Anyone option should be enable for {field}",
  "error.not_matched_with_val": "{field} not matched with {value}",
  "error.please_remove_uploaded_file": "Please remove uploaded file",

  // Success message
  "success.updated_successfully": "{field} updated successfully.",
  "success.lead_status_updated_success": "Lead {status} successfully",

  //Placeholder
  "placeholder.select_field": "Select {field}",
  "placeholder.enter_field": "Enter {field}",
  "placeholder.please_enter_field": "Please enter {field}",
  "placeholder.fix_field": "Fix {field}",
  "placeholder.search": "search",

  // Message
  "message.no_found_data": "no data found",
  "message.this_action_will_reset_all_your_form_data": "Note: This action will reset all your form data",

  // Confirm message
  "confirm.are_you_sure": "Are you sure want to {field}?",
  "confirm.are_you_sure_delete": "Are you sure want to delete {field}?",
  "confirm.are_you_sure_want_to_change_upload_type": "Are you sure want to change upload type?",

  // Buttons
  "btn.cancel": "cancel",
  "btn.submit": "submit",
  "btn.update": "update",
  "btn.reset": "reset",
  "btn.next": "next",
  "btn.back": "back",
  "btn.skip": "skip",
  "btn.save": "save",
  "btn.ok": "ok",
  "btn.reject": "reject",
  "btn.delete": "delete",
  "btn.yes": "yes",
  "btn.no": "no",
  "btn.continue": "continue",
  "btn.your_dashboard": "your dashboard",
  "btn.reset_section": "reset {section}",
  "btn.create_section": "create {section}",
  "btn.accept": "accept",
  "btn.send": "send",

  // home page
  "home.banner.title": "The B2B data marketplace",
  "home.banner.subtitle": "More choice. More value. More results",
  "home.banner.sub_title1": "Add what sales leads you need ",
  "home.banner.sub_title2": "Get matched with the right suppliers",
  "home.banner.sub_title3": "Take your pick from the best bids",
  "home.banner.sub_title4": "Gain access to the contact details of people you want to do business with ",
  "home.info.heading": "Why Leads United?",
  "home.info.heading2": "We’re unique to the market. This platform cuts out the conventional route of briefing one agency and accepting their results. Instead you get the tools to be in charge and the freedom of choice. You’ll only ever be greeted by verified quality results with transparent pricing. ",
  "home.process.title": "Bridging the Gap to Reduce Your Marketing Costs",
  "home.process.sub_title1": "Effectively Recycle Your Unused <br />Leads",
  "home.process.buy_leads": "buy leads",
  "home.process.sub_title2": "Obtain Quality Leads",
  "home.process.sell_leads": "sell leads",
  "home.process.sub_title3": "Integrate with <br />API",
  "home.process.integrate": "integrate",
  "home.why.title": "Leads to sell?",
  "home.why.sub_title": "Come and join our global platform and connect with active buyers. We'll alert you every time someone is searching for leads that match your data. It’s a great way to effectively recycle your unused quality leads.",
  "home.why.btn": "Get involved ",
  "home.why.feature1": "High-quality leads",
  "home.why.feature2": "Complete flexibility",
  "home.why.feature3": "Support 24/7",

  // Contact-US page
  "contact.title": "Contact Us",
  "contact.sub_title": "Contact us to enquire or ask any questions – we are here to help at all times.",

  //Dropzone
  "dropzone.title": "drop file here",
  "dropzone.allowed_files": "Allowed file extensions : {allowedExtensions}",
  "dropzone.file_name": "file name",
  "dropzone.file_delete_success": "File removed successfully.",
  "dropzone.file_delete_error": "Something went wrong while removing file.",
  "dropzone.file_upload_error": "Something went wrong while uploading file.",

  //Generic page
  "generic_page.coming_soon.title": "coming soon",
  "generic_page.coming_soon.sub_title": "This page is under construction.",
  "generic_page.not_found.title": "not found",
  "generic_page.not_found.sub_title": "The requested page is not found.",

  // Date Range picker
  "date_range_picker.default_label": "select a date range",
  "date_range_picker.date_range": "{startDate} - {endDate}",

  // register interest
  "register_interest.title": "Register your Interest",
  "register_interest.sub_title": "Contact us to enquire or ask any questions – we are here to help at all times.",

};