import React from "react";
import { createRoot } from "react-dom/client";

import Root from "./Root";
import "./styles/css/main.css";

const rootEl = document.getElementById("root");

const root = createRoot(rootEl);
root.render(<Root />);

// hot reloading.
if (module.hot) {
  module.hot.accept("./Root", () => {
    const NextApp = require("./Root").default;
    
    root.render(<NextApp />);
  });
}