import React, { Component } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { withCookies, CookiesProvider, Cookies } from "react-cookie";
import { get as _get, map as _map } from "lodash";
import { instanceOf } from "prop-types";
import { ToastContainer } from "react-toastify";
import { IntlProvider } from "react-intl";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import moment from "moment";

import { ApplicationLoader } from "./components/loaders/ApplicationLoader";

// pages
import PublicPages from "./pages/PublicPages";

import { LANGUAGES, DEFAULT_LANGUAGE } from "./helpers/constants";
import config from "./helpers/config";

import { getAccount, updateUri, clearAccountError } from "./actions/oauth";
import { updateLanguage } from "./actions/application";

// loading .env configuration.
require("dotenv").config();

const publicComponents = [
  { name: "Home", path: "/", element: PublicPages }
];

class App extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  constructor(props) {
    super(props);

    // set locale language of moment globally
    require(`moment/locale/${this.props.lang}`);
    moment.locale(this.props.lang, { week: { dow: 0, doy: 6 } });

    const langFileName = _get(LANGUAGES, `${this.props.lang}.file_name`, "en.js");

    this.state = { langFile: require(`./languages/${langFileName}`).default };
  }

  componentDidMount() {
    const { lang, updateUri } = this.props;
    if (typeof updateUri === "function") { updateUri(config.getBackendURL(true, true)); }

    if (typeof updateLanguage === "function") { updateLanguage(lang || "en-gb"); }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.lang !== this.props.lang) {

      // set locale language of moment globally
      require(`moment/locale/${this.props.lang}`);
      moment.locale(this.props.lang, { week: { dow: 0, doy: 6 } });

      const langFileName = _get(LANGUAGES, `${this.props.lang}.file_name`, "en.js");
      const langFile = require(`./languages/${langFileName}`).default;

      this.setState({ langFile });

      if (typeof this.props.updateLanguage === "function") { this.props.updateLanguage(this.props.lang || "en-gb"); }

      // force update the loaded components
      this.forceUpdate();
    }
  }

  render() {
    const { loading, viewport, lang, cookies } = this.props;
    const { langFile = {} } = this.state;

    return (
      <IntlProvider locale={lang} messages={langFile}>
        <CookiesProvider cookies={cookies}>
          <div className={`viewport viewport--${viewport}`}>

            <Helmet defaultTitle="Leads United" />
            <Router>
              <Routes>
                {/* Public routes */}
                {_map(publicComponents, (route, key) => (
                  <Route key={key} path={route.path} element={<route.element />} />
                ))}
              </Routes>
            </Router>
            {(loading) && (<ApplicationLoader />)}
            <ToastContainer />
          </div>
        </CookiesProvider>
      </IntlProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  viewport: _get(state, "application.viewport.mode", "desktop"),
  loading: _get(state, "application.loading", false),
  lang: _get(state, "application.language.defaultLang", DEFAULT_LANGUAGE),
});

const mapDispatchToProps = (dispatch) => ({
  clearAccountError: () => dispatch(clearAccountError()),
  getAccount: (data) => dispatch(getAccount(data)),
  updateUri: (data) => dispatch(updateUri(data)),
  updateLanguage: (data) => dispatch(updateLanguage(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(withCookies(App));
