export const UPDATE_APPLICATION_LOADING = "UPDATE_APPLICATION_LOADING";

export const updateLoadingState = (loading) => {
  return ({
    type: UPDATE_APPLICATION_LOADING,
    loading
  });
}

export const UPDATE_LANGUAGE = "UPDATE_LANGUAGE";

export const updateLanguage = (language) => {
  return ({
    type: UPDATE_LANGUAGE,
    language
  });
}

export const FETCH_APPLICATION_REQUEST = "FETCH_APPLICATION_REQUEST";
export const FETCH_APPLICATION_SUCCESS = "FETCH_APPLICATION_SUCCESS";

export const fetchApplication = () => {
  return ({
    type: FETCH_APPLICATION_REQUEST,
  });
}

export const SET_ACTIVE_MENU = "SET_ACTIVE_MENU";

export const setActiveMenuRequest = (payload) => {
  return ({
    type: SET_ACTIVE_MENU,
    payload
  });
}

export const SET_ADMIN_MENU_COLLAPSE = "SET_ADMIN_MENU_COLLAPSE";
export const setAdminMenuCollapse = (payload) => {
  return ({ type: SET_ADMIN_MENU_COLLAPSE, payload });
}

//Leads Inited Invoice Data
export const FETCH_INVOICES_REQUEST = "FETCH_INVOICES_REQUEST";
export const FETCH_INVOICES_SUCCESS = "FETCH_INVOICES_SUCCESS";
export const FETCH_INVOICES_ERROR = "FETCH_INVOICES_ERROR";

export const fetchInvoices = (payload) => {

  return ({
    type: FETCH_INVOICES_REQUEST,
    payload
  });
}

export const RESET_INVOICES_ERROR = "RESET_INVOICES_ERROR";

export const resetInvoiceError = () => {
  return ({ type: RESET_INVOICES_ERROR });
}

// My Team
export const FETCH_MY_TEAM_DATA_REQUEST = "FETCH_MY_TEAM_DATA_REQUEST";
export const FETCH_MY_TEAM_DATA_SUCCESS = "FETCH_MY_TEAM_DATA_SUCCESS";
export const FETCH_MY_TEAM_DATA_ERROR = "FETCH_MY_TEAM_DATA_ERROR";

export const fetchMyTeamData = (payload) => {
  
  return ({
    type: FETCH_MY_TEAM_DATA_REQUEST,
    payload
  });
}

export const RESET_MY_TEAM_DATA_ERROR = "RESET_MY_TEAM_DATA_ERROR";

export const resetMyTeamDataError = () => {
  return ({ type: RESET_MY_TEAM_DATA_ERROR });
}