// Language configuration

import moment from 'moment';
export const LANGUAGES = {
  "en-gb": {
    id: "en-gb",
    name: "english",
    file_name: "en.js"
  }
};

//Default web language
export const DEFAULT_LANGUAGE = "en-gb";

// Sidebar Menu
export const sidebarMenuConfig = {
  "marketplace": {
    height: "14px",
    width: "14px",
    name: "marketplace",
    group: "marketplace",
    route: "marketplace",
    icon: "iconMarketplace",
    containSubMenu: false,
    menuLanguageKey: "marketplace",
  },
  "dashboard": {
    height: "12px",
    width: "14px",
    name: "dashboard",
    group: "dashboard",
    route: "dashboard",
    icon: "iconDashboard",
    containSubMenu: false,
    menuLanguageKey: "dashboard",
  },
  "buy_leads": {
    height: "19px",
    width: "19px",
    name: "buy_leads",
    group: "buy_leads",
    route: "leads/buy-leads",
    icon: "iconBuyLeads",
    containSubMenu: false,
    menuLanguageKey: "buy_leads",
  },
  "sell_leads": {
    height: "19px",
    width: "19px",
    name: "sell_leads",
    group: "sell_leads",
    route: "leads/sell-leads",
    icon: "iconSellLeads",
    containSubMenu: false,
    menuLanguageKey: "sell_leads",
  },
  "enrich": {
    height: "15px",
    width: "15px",
    name: "enrich",
    group: "enrich",
    route: "leads/enrich",
    icon: "iconMagic",
    containSubMenu: false,
    menuLanguageKey: "enrich",
  },
  "api": {
    height: "17px",
    width: "18px",
    icon: "iconSmShare",
    name: "api",
    group: "api",
    route: "api",
    containSubMenu: false,
    menuLanguageKey: "api",
  },
  "my_team": {
    height: "15px",
    width: "15px",
    icon: "iconTeam",
    name: "my_team",
    group: "my_team",
    route: "team",
    containSubMenu: false,
    menuLanguageKey: "my_team",
  },
  "my_invoice": {
    height: "16px",
    width: "13px",
    icon: "iconInvoice",
    name: "my_invoice",
    group: "my_invoice",
    route: "invoice",
    containSubMenu: false,
    menuLanguageKey: "my_invoice",
  },
  "settings": {
    height: "15px",
    width: "15px",
    icon: "iconSetting",
    name: "settings",
    group: "settings",
    route: "settings",
    containSubMenu: false,
    menuLanguageKey: "settings",
  },
};

export const tmpConstant = {
  "datepickerFormat": "dd/MM/yyyy",
  "displayDateFormat": "DD/MM/YYYY",
  "APIRequestDateFormat": "YYYY-MM-DD",
  "APIRequestFullDateFormat": "YYYY-MM-DD hh:mm:ss",
  "displayDateTimeFormat": "DD/MM/YYYY HH:mm:ss",
  "allowedDataUploadFiles": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, text/csv",
  "allowedDataUploadFilesLabel": ".xlsx, .xls, .csv",
  "allowedEnhanceUploadFiles": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, text/csv",
  "allowedEnhanceUploadFilesLabel": ".xlsx, .xls, .csv",
  "dateRangeSlots": {
    "No Date": [null, null],
    "Today": [moment().toDate(), moment().toDate()],
    "Yesterday": [
      moment().subtract(1, 'days').toDate(),
      moment().subtract(1, 'days').toDate(),
    ],
    'Last 7 Days': [
      moment().subtract(6, 'days').toDate(),
      moment().toDate(),
    ],
    'Last 30 Days': [
      moment().subtract(29, 'days').toDate(),
      moment().toDate(),
    ],
    'This Month': [
      moment().startOf('month').toDate(),
      moment().endOf('month').toDate(),
    ],
    'Last Month': [
      moment().subtract(1, 'month').startOf('month').toDate(),
      moment().subtract(1, 'month').endOf('month').toDate(),
    ],
  }
};

// Application routes
export const appRoutes = [
  {
    path: "/marketplace",
    exact: true,
    component: "marketPlace",
    activeMenu: { group: "marketplace", name: "marketplace" }
  }, {
    path: "/dashboard",
    exact: true,
    component: "dashboard",
    activeMenu: { group: "dashboard", name: "dashboard" }
  }, {
    path: "/leads/buy-leads",
    exact: true,
    component: "buyLeads",
    activeMenu: { group: "buy_leads", name: "buy_leads" }
  }, {
    path: "/leads/sell-leads",
    exact: true,
    component: "sellLeads",
    activeMenu: { group: "sell_leads", name: "sell_leads" }
  }, {
    path: "/leads/enrich",
    exact: true,
    component: "underConstruction",
    activeMenu: { group: "enrich", name: "enrich" }
  }, {
    path: "/api",
    exact: true,
    component: "APIKeys",
    activeMenu: { group: "api", name: "api" }
  }, {
    path: "/team",
    exact: true,
    component: "myTeam",
    activeMenu: { group: "my_team", name: "my_team" }
  }, {
    path: "/vendor-quote/:id",
    exact: true,
    component: "vendorQuote",
    activeMenu: { group: "dashboard", name: "dashboard" }
  },
  {
    path: "/vendor-reply/:id",
    exact: true,
    component: "vendorReply",
    activeMenu: { group: "dashboard", name: "dashboard" }
  },
  // Auth/User
  {
    path: "/invoice",
    exact: true,
    component: "invoices",
    activeMenu: { group: "my_invoice", name: "my_invoice" }
  }, {
    path: "/settings",
    exact: true,
    component: "underConstruction",
    activeMenu: { group: "settings", name: "settings" }
  }, {
    path: "/change-password",
    exact: true,
    component: "changePassword",
    activeMenu: { group: "", name: "" }
  }
];