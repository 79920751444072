import { combineReducers } from "redux";
import actionTypes from "../actions";

/*
 * Reducer to store uri
 */
export const uri = (state = null, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_URI:
      return action.uri;

    default:
      return state;
  }
};

/*
 * Reducer to store logged in user 's token
 */
// export const token = (state = JSON.parse(localStorage.getItem('__token')), action) => {
export const token = (state = null, action) => {
  switch (action.type) {
    case actionTypes.OAUTH_LOGIN_SUCCESS:
    case actionTypes.OAUTH_SET_TOKEN:
      return action.token;

    case actionTypes.DELETE_OAUTH_TOKEN_REQUEST:
      // localStorage.removeItem("__token");
      return null;

    default:
      return state;
  }
};

/*
 * Reducer to store logged in user 's data
 */
const user = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.FETCH_APPLICATION_SUCCESS:
      return action.payload.user;

    case actionTypes.DELETE_OAUTH_TOKEN_REQUEST:
      return {};

    default:
      return state;
  }
}

const success = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.CREATE_CONTACT_US_SUCCESS:
    case actionTypes.CHANGE_PASSWORD_SUCCESS:
    case actionTypes.FORGOT_PASSWORD_SUCCESS:
    case actionTypes.RESET_PASSWORD_SUCCESS:
    case actionTypes.OAUTH_SIGNUP_SUCCESS:
      return (action.message || {});

    case actionTypes.CLEAR_CREATE_CONTACT_US_RESPONSE:
    case actionTypes.CLEAR_CHANGE_PASSWORD_RESPONSE:
    case actionTypes.CLEAR_OAUTH_SUCCESS:
      return {};

    default:
      return state;
  }
}

/*
 * Reducer to store all oauth errors
 */
const error = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.CREATE_CONTACT_US_ERROR:
    case actionTypes.RESET_PASSWORD_INVALID:
    case actionTypes.CHANGE_PASSWORD_ERROR:
    case actionTypes.FORGOT_PASSWORD_ERROR:
    case actionTypes.RESET_PASSWORD_ERROR:
    case actionTypes.OAUTH_SIGNUP_ERROR:
    case actionTypes.OAUTH_LOGIN_ERROR:
      return (action.error || {});

    case actionTypes.CLEAR_CREATE_CONTACT_US_RESPONSE:
    case actionTypes.CLEAR_CHANGE_PASSWORD_ERROR:
    case actionTypes.CLEAR_FORGOT_ERROR:
    case actionTypes.CLEAR_OAUTH_ERROR:
      return {};

    default:
      return state;
  }
}

/*
 * Reducer to store forgot password success response
 */
const forgotResponse = (state = false, action) => {
  switch (action.type) {
    case actionTypes.FORGOT_PASSWORD_SUCCESS:
      return action.data;

    case actionTypes.FORGOT_PASSWORD_ERROR:
    case actionTypes.CLEAR_FORGOT_RESPONSE:
      return false;

    default:
      return state;
  }
}

const resetResponse = (state = true, action) => {
  switch (action.type) {
    case actionTypes.RESET_PASSWORD_VALID:
      return action.data;

    case actionTypes.RESET_PASSWORD_INVALID:
      return false;

    case actionTypes.CLEAR_CHECK_RESET_RESPONSE:
      return true;

    default:
      return state;
  }
}

const resetPassword = (state = false, action) => {
  switch (action.type) {
    case actionTypes.RESET_PASSWORD_SUCCESS:
      return action.data;

    case actionTypes.RESET_PASSWORD_ERROR:
      return false;

    case actionTypes.CLEAR_RESET_RESPONSE:
      return false;

    default:
      return state;
  }
}

const resetToken = (state = false, action) => {
  switch (action.type) {
    case actionTypes.RESET_PASSWORD_VALID:
      return action.resetToken;

    case actionTypes.RESET_PASSWORD_INVALID:
      return false;

    case actionTypes.CLEAR_RESET_RESPONSE:
      return false;

    default:
      return state;
  }
}

const changePasswordResponse = (state = false, action) => {
  switch (action.type) {
    case actionTypes.CHANGE_PASSWORD_SUCCESS:
      return action.data;

    case actionTypes.CHANGE_PASSWORD_ERROR:
      return false;

    case actionTypes.CLEAR_CHANGE_PASSWORD_RESPONSE:
      return false;

    default:
      return state;
  }
}

export default {
  oauth: combineReducers({
    uri,
    token,
    user,
    error,
    success,
    forgotResponse,
    resetResponse,
    resetPassword,
    resetToken,
    changePasswordResponse
  })
};
