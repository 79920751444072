import { get as _get } from "lodash";
import api from "../helpers/api";

export const getApplication = (uri) => {
	return api().get("/application/configuration").then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: _get(res, "data.data", {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while fetching application configuration."),
			data: {}
		};
	});
}


export const getAPIKey = () => {
	return api().get("/user/get-api-key").then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: _get(res, "data.data", {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while fetching API key."),
			data: {}
		};
	});
}

export const resetAPIKey = () => {
	return api().put("/user/reset-api-key", {}).then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: (res.data || {})
		};
	}).catch((err) => {

		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong rest API key."),
			data: {}
		};
	});
}

export const getInvoices = () => {
	return api().get("/user/user_payment_receipt").then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: _get(res, "data.data", {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while fetching invoices."),
			data: {}
		};
	});
}

export const addTeamMember = (variables) => {
	return api().post("/user/add-team-member", variables).then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: (res.data || {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while adding team member."),
			data: {}
		};
	});
}

export const getMyTeamData = () => {
	return api().get("/user/get-team-members").then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: _get(res, "data.data", {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while fetching my team details."),
			data: {}
		};
	});
}

export const deleteTeamMember = (variables) => {
	return api().delete("/user/delete-team-member", { data: variables }).then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: _get(res, "data", {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while deleting team member."),
			data: {}
		};
	});
}

export const addRegisterInterest = (variables) => {
	return api().post("/register-interest", variables).then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: (res.data || {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while registering your interest."),
			data: {}
		};
	});
}